import React, { useCallback, useEffect, useState } from 'react'
import { Box, Flex, Text, useToast, Button } from '@chakra-ui/react'
import { isLocalEnv } from '../helpers/is-local-env'
import { environment } from '../../environments/environment'
import { useUploadPublicAssetLocal } from '../hooks/useUploadPublicAssetLocal'
import { useGetPresignedUrl } from '../hooks/useGetPresignedUrl'
import { VersionWidget } from '../components/VersionWidget'

interface Version {
    version: string
    header: string
    body: string
}

interface AppVersions {
    required: Version
    recommended: Version
}

export function AppVersionSection() {
    const [cacheBuster, setCachebuster] = useState(Date.now())

    //S3
    const [uploadFile] = useUploadPublicAssetLocal()
    const [getSignedUrl] = useGetPresignedUrl()

    const toast = useToast()

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string | undefined>(undefined)
    const [appVersions, setAppVersions] = useState<AppVersions | undefined>(
        undefined,
    )

    function handleUpdateAppVersion(
        updatedVersion: Version & { name: string },
    ) {
        const clonedAppVersions = appVersions && structuredClone(appVersions)

        //update specific version
        if (updatedVersion.name === 'required') {
            clonedAppVersions.required.version = updatedVersion.version
            clonedAppVersions.required.header = updatedVersion.header
            clonedAppVersions.required.body = updatedVersion.body
        } else {
            clonedAppVersions.recommended.version = updatedVersion.version
            clonedAppVersions.recommended.header = updatedVersion.header
            clonedAppVersions.recommended.body = updatedVersion.body
        }

        clonedAppVersions && writeAppVersionsData(clonedAppVersions)
    }

    const fetchAppVersionData = useCallback(async () => {
        setLoading(true)
        fetch(
            isLocalEnv()
                ? `${environment.apiGatewayUrl}/public-assets/app-version.json?cachebust=${cacheBuster}`
                : `https://${environment.publicBucketName}.s3.ap-southeast-2.amazonaws.com/public-assets/app-version.json`,
            {
                method: 'GET',
            },
        )
            .then(async (resp) => {
                setLoading(false)
                if (resp.status !== 200) {
                    toast({
                        title: "Couldn't retrieve app version data.",
                        description:
                            'Please check the console and contact the dev team 💀💀💀',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    })
                    console.error('Error retrieving app version data', resp)
                    setError("Couldn't retrieve app version data.")
                } else {
                    const fetchedResp: any = await resp.json()
                    console.debug('Fetched app version data.', fetchedResp)
                    setAppVersions(fetchedResp)
                }
            })
            .catch((e) => {
                setLoading(false)
                setError("Couldn't retrieve App version data.")
                console.error("Couldn't retrieve App version data.", e)
            })
    }, [cacheBuster, toast])

    async function writeAppVersionsData(data: AppVersions) {
        const filename = 'app-version.json'
        const contentType = 'application/json'

        const jsonString = JSON.stringify(data)
        const jsonBlob = new Blob([jsonString], {
            type: contentType,
        })
        const jsonFile = new File([jsonBlob], filename, {
            type: contentType,
        })

        setLoading(true)
        if (isLocalEnv()) {
            await uploadFile({
                file: jsonFile,
                filename: filename,
            })
            setCachebuster(Date.now())
        } else {
            try {
                const response = await getSignedUrl({ filename, contentType })

                if (!response) {
                    throw Error('No response from server')
                }

                const headers = new Headers()
                headers.append('Cache-Control', `public, max-age=120`)

                const result = await fetch(response.url, {
                    method: 'PUT',
                    body: jsonFile,
                    headers,
                })

                if (result.status !== 200) {
                    toast({
                        title: 'Oh no something went wrong!',
                        description:
                            'There was an error uploading the file - please check the console and contact the dev team if the problem persists',
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    })
                    console.error('Write sponsor data:', JSON.stringify(result))
                } else {
                    toast({
                        title: 'Success',
                        description: 'App Versions has been updated',
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                    setCachebuster(Date.now())
                }
            } catch (e) {
                setLoading(false)
                console.error('Couldnt upload file / app version', e)
            }
        }
    }

    //
    useEffect(() => {
        fetchAppVersionData()
    }, [cacheBuster, fetchAppVersionData])

    return (
        <Box>
            <Text fontSize={'xx-large'} fontWeight="bold">
                App Versions
            </Text>
            <Text fontSize={'sm'} paddingBottom={4}>
                Settings to change version requirement for mobile app.
            </Text>
            {error ? (
                <Box>
                    <Text color="red">{`Error: ${error}`}</Text>
                    <Button onClick={fetchAppVersionData}>Try Again</Button>
                </Box>
            ) : (
                appVersions && (
                    <Flex gap={4} flexWrap="wrap" justifyContent="left">
                        <VersionWidget
                            versionProps={appVersions.required}
                            loading={loading}
                            handleUpdateAppVersion={handleUpdateAppVersion}
                            name="required"
                        />
                        <VersionWidget
                            versionProps={appVersions.recommended}
                            handleUpdateAppVersion={handleUpdateAppVersion}
                            loading={loading}
                            name="recommended"
                        />
                    </Flex>
                )
            )}
        </Box>
    )
}
